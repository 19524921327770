import React, { useState, useEffect, useMemo } from 'react';
import { Form, Input, Button, Checkbox, IconNames, Icon } from '@sd-ui-components/core';
import { useIntl, FormattedMessage } from 'react-intl';
import './Login.scss';
import { AxiosError } from 'axios';
import { LoginError } from '../../models/LoginError';
import { constructLoginError } from '../../apis/Authentication.api';
import { AppConfigContext } from '../../contexts/AppConfigContext';
import { ErrorTypes, isFatalError } from '../../utils/errorClassification';
import { AlertType } from '../../models/AlertType';
import { useAuthentication } from '../../providers/AuthenticationProvider';
import { useLogin } from '../../providers/LoginProvider';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import { useAuthorization } from '../../providers/AuthorizationProvider';
import { JL } from 'jsnlog';

export const LoginForm: React.FunctionComponent = () => {
  const intl = useIntl();
  const history = useHistory();
  const config = React.useContext(AppConfigContext);
  const { isAuthorizing } = useAuthorization();
  const { isLoggingIn } = useAuthentication();

  const [company, setCompany] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const { login } = useAuthentication();
  const { updateLoginError } = useLogin();
  const [cookies, setCookie] = useCookies(['company', 'username']);
  const waiting = useMemo(()=> {

    return isLoggingIn || isAuthorizing
  
  }, [isLoggingIn, isAuthorizing])
  
  
  const maxLengthForInputs = {
    company: 100,
    username: 254,
    password: 30,
  };

  const handleCompanyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newCompany = e.target.value;
    setCompany(newCompany);
  };

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newUsername = e.target.value;
    setUsername(newUsername);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
  };

  const toggleRememberMe = () => {
    setRememberMe(!rememberMe);
  };

  const canLogin = (): boolean => {
    return !!(company && password && username);
  };

  const handleLoginError = (error: AxiosError) => {
    const loginError: LoginError = constructLoginError(error);

    updateLoginError(loginError);
    if (isFatalError(loginError.messageDescriptor.id)) {
      history.push('/error');
    }
  };

  const persistLoginDetailsToStorage = () => {
    setCookie('company', company, { path: '/' });
    setCookie('username', username, { path: '/' });
  };

  const loadLoginDetailsFromStorage = () => {
    if (cookies.company) {
      setCompany(cookies.company);
    }
    if (cookies.username) {
      setUsername(cookies.username);
    }
  };

  useEffect(() => {
    loadLoginDetailsFromStorage();
  }, []);

  useEffect(() => {
    let error: any;
    
    if (company.length > maxLengthForInputs.company) {
      error = {
        id: ErrorTypes.MaxLimitReachedForCompany,
        defaultMessage: 'The company name should not exceed 100 characters.',
      };
    } else if (username.length > maxLengthForInputs.username) {
      error = {
        id: ErrorTypes.MaxLimitReachedForUsername,
        defaultMessage: 'The user name should not exceed 254 characters.',
      };
    } else if (password.length > maxLengthForInputs.password) {
      error = {
        id: ErrorTypes.MaxLimitReachedForPassword,
        defaultMessage: 'The password should not exceed 30 characters.',
      };
    } else {
      error = {};
    }

    const loginError: LoginError = new LoginError(AlertType.Error, error.id ? error : {});

    updateLoginError(loginError);
  }, [company, username, password]);

  const handleLoginAction = async () => {
    if (!canLogin()) {
      return;
    }

    try {
      await login(company, username, password);

      if (rememberMe) {
        JL().debug('User selected "Remember Me". Persisting user login details to storage');
        persistLoginDetailsToStorage();
      }
    } catch (error) {
      handleLoginError(error);
    }
  };

  return (
    <Form className="login-form">
      <div className="page-content">
        <div>
          <Input
            aria-label="company"
            name="company"
            autoComplete="company"
            className="login-company-input"
            value={company}
            placeholder={intl.formatMessage({
              id: 'Login.Company',
              defaultMessage: 'Company',
            })}
            onChange={handleCompanyChange}
            disabled={waiting}
          />
        </div>
        <div>
          <Input
            aria-label="username"
            name="username"
            autoComplete="username"
            className="login-input"
            value={username}
            placeholder={intl.formatMessage({
              id: 'Login.Username',
              defaultMessage: 'User name',
            })}
            onChange={handleUsernameChange}
            disabled={waiting}
          />
        </div>
        <div>
          <Input
            aria-label="password"
            autoComplete="current-password"
            name="password"
            type="password"
            className="login-input"
            value={password}
            placeholder={intl.formatMessage({
              id: 'Login.Password',
              defaultMessage: 'Password',
            })}
            onChange={handlePasswordChange}
            onPressEnter={handleLoginAction}
            disabled={waiting}
          />
        </div>
        <div className="login-password-remember-me-row">
          <Checkbox name="rememberMe" checked={rememberMe} onClick={toggleRememberMe} disabled={waiting}>
            <FormattedMessage id="Login.RememberMe" defaultMessage="Remember me on this device" />
          </Checkbox>
          <a href={`${config.responseCenterUrl}/UserAccount/ForgotPassword`} className="login-link-forgot-password">
            <FormattedMessage id="Login.ForgotPassword" defaultMessage="Forgot password?" />
          </a>
        </div>
      </div>

      <div className="page-actions">
        <div>
          <Button type="primary" size="large" className="login-button" onClick={handleLoginAction} loading={false}>
            {waiting ? (
              <div className="loading">
                <Icon className="rotate" icon={IconNames.Loading} />
              </div>
            ) : (
              <FormattedMessage id="Login.LogIn" defaultMessage="Log in" />
            )}
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default LoginForm;
