import * as React from 'react';
import { Alert } from '@sd-ui-components/core';
import { useIntl, FormattedMessage } from 'react-intl';
import { LoginContext } from '../../contexts/LoginContext';
import { AppConfigContext } from '../../contexts/AppConfigContext';
import { isAccessError } from '../../utils/errorClassification';

export const LoginErrors: React.FunctionComponent = () => {
  const intl = useIntl();
  const config = React.useContext(AppConfigContext);

  return (
    <LoginContext.Consumer>
      {loginContext => (
        <div>
          {loginContext.loginError.messageDescriptor.id &&
            <Alert showIcon={true} type={loginContext.loginError.type} message={<div className="error-text">{intl.formatMessage(loginContext.loginError.messageDescriptor)}
              {!isAccessError(loginContext.loginError.messageDescriptor.id) && 
              <div>
                <FormattedMessage
                  id="Login.Errors.NonFatalDirections"
                  defaultMessage=" Please try again, or try logging into the <responseCenterLink>Response Center</responseCenterLink> or <driverAppLink>Driver App</driverAppLink>."
                  values={{
                    responseCenterLink: (msg: any) => <a href={config.responseCenterUrl}>{msg}</a>,
                    driverAppLink: (msg: any) => <a href={config.driverAppUrl}>{msg}</a>
                  }}
                />
              </div>}
            </div>} />
          }
        </div>
      )}
    </LoginContext.Consumer>
  )
}

export default LoginErrors;