import { useStorage } from '@sd-ui/persistence';

export interface PersistedSettings {
  searchParameters: any;
}

const initialSettings = {
  usersWhoAcceptedDisclaimer: [],
};

export const usePersistedSettings = () => {
  const [persistedSettings, setPersistedSettings] = useStorage('persistedSettings', JSON.stringify(initialSettings));
  const parsedSettings = persistedSettings ? JSON.parse(persistedSettings) : initialSettings

  const updatePersistedSettings = (values: string) => {
    const settingsToStore = Object.assign(parsedSettings, JSON.parse(values));

    setPersistedSettings(JSON.stringify(settingsToStore));
  };

  return [parsedSettings, updatePersistedSettings] as const;
};