
export enum ErrorTypes {
  Unknown = 'Login.Errors.Unknown',
  IncorrectLoginInformation = 'Login.Errors.IncorrectLoginInformation',
  PermissionError = 'Login.Errors.PermissionError',
  BadRequestError = 'Login.Errors.BadRequestError',
  ServerError = 'Login.Errors.ServerError',
  MaxLimitReachedForCompany = 'Login.Errors.MaxLimitReachedForCompany',
  MaxLimitReachedForUsername = 'Login.Errors.MaxLimitReachedForUsername',
  MaxLimitReachedForPassword = 'Login.Errors.MaxLimitReachedForPassword',
  UserIsNotAuthorized = 'Login.Errors.UserIsNotAuthorized'
}

export const isAccessError = (errorId?: string | number): boolean => {
  return errorId === ErrorTypes.UserIsNotAuthorized || errorId === ErrorTypes.PermissionError;
}

export const isFatalError = (errorId?: string | number): boolean => {
  return errorId === ErrorTypes.ServerError || errorId === ErrorTypes.Unknown;
}