import * as React from 'react';
import { Icon, IconNames } from '@sd-ui-components/core';
import { LoginContext } from '../../contexts/LoginContext';
import { useIntl, FormattedMessage } from 'react-intl';
import './FatalError.scss';
import { Routes } from '../../models/Routes';
import { Redirect } from 'react-router-dom';

export const FatalError: React.FunctionComponent = () => {
  const intl = useIntl();

  return (
    <React.Fragment>
      <div className="sd-content error-root">
        <div className="error-header">
          <Icon
            icon={IconNames.ExclamationCircleO}
            size={73}
            className="error-icon"
          />
        </div>
        <LoginContext.Consumer>
          {loginContext => (
            <div className="error-content">
              <h1 className="error-content-body">
                {loginContext.loginError.messageDescriptor.id ? intl.formatMessage(loginContext.loginError.messageDescriptor) : <Redirect to={Routes.Login} />}
              </h1>
              <h2 className="error-content-summary">
                <FormattedMessage
                  id="Login.Errors.FatalDirections"
                  defaultMessage="Please try again later."
                />
              </h2>
            </div>
          )}
        </LoginContext.Consumer>
      </div>
    </React.Fragment>
  )
}

export default FatalError;