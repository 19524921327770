import React from 'react';
import { useMessages } from '@sd-ui/messages';
import { IntlProvider } from 'react-intl';
export const LocalizedApplication: React.FunctionComponent = ({ children }) => {
  const locale = 'en-us';
  const { messages, loading } = useMessages(locale);
  return (
    <IntlProvider locale={locale} messages={messages}>
      {loading ? 'Loading...' : children}
    </IntlProvider>
  );
};
export default LocalizedApplication;