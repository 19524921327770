import { AlertType } from './AlertType';
import { MessageDescriptor } from 'react-intl';

export class LoginError {
  type: AlertType;
  messageDescriptor: MessageDescriptor;
  messageParams?: { [key: string]: string };

  constructor(type: AlertType, messageDescriptor: MessageDescriptor, messageParams?: { [key: string]: string }) {
    this.type = type;
    this.messageDescriptor = messageDescriptor;
    this.messageParams = messageParams;
  }
}