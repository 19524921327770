import React, { useState, useContext, useMemo } from 'react';
import { AuthenticationContext } from '../contexts/AuthenticationContext';
import { UserInfo } from '../models/UserInfo';
import { AppConfigContext } from '../contexts/AppConfigContext';
import { AuthenticationResponse } from '../models/AuthenticationResponse';
import { DecodedAccessToken } from '../models/DecodedAccessToken';
import {
  getExpiresInFromExpiresAt,
  postLogin,
  deleteSession
} from '../apis/Authentication.api';
import { AxiosError } from 'axios';
import jwt_decode from 'jwt-decode';
import { JL } from 'jsnlog';
import { setupJsnLogWithAjaxAppender } from '../apis/Logging.api';

export const useAuthentication = () => {
  return useContext(AuthenticationContext);
}

const AuthenticationProvider: React.FunctionComponent = ({ children }) => {

  const [accessToken, setAccessToken] = useState('');
  const [expiresAt, setExpiresAt] = useState(0);
  const [userInfo, setUserInfo] = useState({});
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isLoggingInitialized, setIsLoggingInitialized] = useState(false);
  const config = useContext(AppConfigContext);

  const setupJsnLog = (accessToken: string) => {
    if (isLoggingInitialized) {
      return;
    }

    setupJsnLogWithAjaxAppender(accessToken, config);
    setIsLoggingInitialized(true);
  }

  /**
   * Performs a login using username, password, and company against the authentication service.
   *
   * @param {string} company
   * @param {string} username
   * @param {string} password
   * @throws {AxiosError | GQLError} If login fails.
   * @returns {Promise<UserInfo>} Upon successful login
   */
  const login = async (company: string, username: string, password: string): Promise<UserInfo> => {
    const authenticationServiceUrl = config.authenticationServiceUrl || "";

    setIsLoggingIn(true);

    try {
      const loginInfo: AuthenticationResponse = await postLogin(authenticationServiceUrl, company, username, password);
      const { accessToken } = loginInfo;

      setupJsnLog(accessToken);
      handleSuccessfulLogin(accessToken);
      setIsLoggingIn(false);
      JL().debug(`Successful authentication for user ${username}@${company}`);
      return userInfo;
    } catch (error) {
      setIsLoggingIn(false);
      const axiosError = error as AxiosError;
      throw axiosError;
    }
  }

  const logout = async () => {
    const authenticationServiceUrl = config.authenticationServiceUrl || "";
    await deleteSession(authenticationServiceUrl);
  }

  const handleSuccessfulLogin = (accessToken: string) => {
    const expiresAt = getExpiresAtFromAccessToken(accessToken);

    setAccessToken(accessToken);
    setExpiresAt(expiresAt);

    initUserInfoFromAccessToken(accessToken);
  }

  const getExpiresAtFromAccessToken = (accessToken: string): number => {
    const decodedTokenObject = jwt_decode<DecodedAccessToken>(accessToken);
    let expiresAt = 0;

    if (decodedTokenObject.exp) {
      expiresAt = decodedTokenObject.exp * 1000;
    } else {
      expiresAt = getDefaultExpiresAt();
    }

    return expiresAt;
  }

  const initUserInfoFromAccessToken = (accessToken: string) => {
    if (!accessToken) {
      return;
    }

    try {
      const decodedTokenObject = jwt_decode<DecodedAccessToken>(accessToken);
      if (decodedTokenObject) {
        let email: string;

        if (decodedTokenObject.email) {
          email = decodedTokenObject.email;
        } else {
          email = decodedTokenObject["https://smartdrivesystems.com/email"]!
        }

        const decodedUserInfo = {
          email,
          username: decodedTokenObject['https://smartdrivesystems.com/username'],
          companyHash: decodedTokenObject['https://smartdrivesystems.com/company_hash']
        };

        setUserInfo(decodedUserInfo);

        JL().debug(`User info successfully read from token: ${JSON.stringify(decodedUserInfo)}`);
      }
    } catch (invalaccessTokenError) {
      JL().fatalException(`Invalid access token: "${accessToken}" could not be decoded`, invalaccessTokenError);
      setUserInfo({});
    }
  }

  /**
   * Returns a default expires at of one hour from now.
   * @private
   * @returns
   */
  const getDefaultExpiresAt = (): number => {
    return (60 * 60 * 1000) + new Date().getTime();
  }

  const expiresIn = () => {
    if (!expiresAt) {
      return 0;
    }

    return getExpiresInFromExpiresAt(expiresAt);
  }

  const isTokenExpired = useMemo(() => {
    if (!expiresAt) {
      return true;
    }

    return expiresAt < new Date().getTime();
  }, [expiresAt]);

  const isAuthenticated = useMemo(() => {
    return !!accessToken && !isTokenExpired;
  }, [accessToken, isTokenExpired]);

  return (
    <AuthenticationContext.Provider
      value={{
        accessToken,
        expiresAt,
        expiresIn,
        isTokenExpired,
        isAuthenticated,
        userInfo,
        isLoggingIn,
        login,
        logout
      }}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationProvider;
