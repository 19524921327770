import 'react-app-polyfill/ie9';
import '@sd-ui-components/core/dist/styles/smartdrive.css';
import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import HttpsRedirect from 'react-https-redirect';
import { ApplicationRoot } from './components/ApplicationRoot/ApplicationRoot';
import { MainRoutes } from './routes';
import AppConfigProvider from './providers/AppConfigProvider';
import ApolloClientProvider from './providers/ApolloClientProvider';
import LocalizedApplication from './components/LocalizedApplication';
import LoginProvider from './providers/LoginProvider';
import AuthenticationProvider from './providers/AuthenticationProvider';
import AuthorizationProvider from './providers/AuthorizationProvider';
import { CookiesProvider } from 'react-cookie';
import * as serviceWorkerRegistration from './registerServiceWorker';

(async () => {
  const Root = (
    <HttpsRedirect>
        <AppConfigProvider>
          <LocalizedApplication>
            <ApplicationRoot>
              <LoginProvider>
                <AuthenticationProvider>
                  <ApolloClientProvider>
                    <AuthorizationProvider>
                      <CookiesProvider>
                        <MainRoutes />
                      </CookiesProvider>
                    </AuthorizationProvider>
                  </ApolloClientProvider>
                </AuthenticationProvider>
              </LoginProvider>
            </ApplicationRoot>
          </LocalizedApplication>
        </AppConfigProvider>
    </HttpsRedirect>
  );

  ReactDOM.render(Root, document.getElementById('root'));
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://cra.link/PWA
  serviceWorkerRegistration.unregister();
})();

// Code-splitting using react-router
// https://github.com/AnomalyInnovations/serverless-stack-demo-client/tree/code-splitting-in-create-react-app
