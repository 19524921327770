export const redirectToUrl = (toUrl: string) => {
  window.location.assign(toUrl);
};

export const redirectToEndpointWithRedirect = (
  endpointUrl: string,
  redirect: string,
  target: string = '_self'
) => {
  window.open(`${endpointUrl}?returnUrl=${redirect}`, target);
};

export const redirectToLogout = (logoutUrl: string, replace = true) => {
  if (replace) {
    return window.location.replace(logoutUrl);
  }

  return (window.location.assign(logoutUrl));
};