import React, { useEffect, useState } from 'react';
import { Layout, Button, IconNames, Icon, Modal } from '@sd-ui-components/core';
import { FormattedMessage } from 'react-intl';
import './Login.scss';
import { useAuthentication } from '../../providers/AuthenticationProvider';
import { LoginErrors } from './LoginErrors';
import { LoginForm } from './LoginForm';
import { NoForm } from './NoForm';
import { ReactComponent as SmartDriveLogo } from '../../assets/smartdrive_logo_light.svg';
import { LoginContext } from '../../contexts/LoginContext';
import GenericTroubleshooting from '../Common/GenericTroubleshooting';
import { isAccessError } from '../../utils/errorClassification';
import { useAuthorization } from '../../providers/AuthorizationProvider';
import { redirectToUrl } from '../../utils/redirect';
import { Routes } from '../../models/Routes';
import { Redirect } from 'react-router';
import { usePersistedSettings } from '../../hooks/usePersistedSettings';
import { JL } from 'jsnlog';

const { Content } = Layout;
const AppFooter = React.lazy(() => import('../AppPage/AppFooter'));

export const Login: React.FunctionComponent = () => {
  const {
    isUserAuthorized,
    fatalAuthorizationError,
    userPeopleHash,
    userLandingPage
  } = useAuthorization();
  const { accessToken } = useAuthentication();
  const [persistedSettings] = usePersistedSettings();

  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const [redirectRoute, setRedirectRoute] = useState('');

  const toggleHelpModal = () => {
    setIsHelpModalOpen(!isHelpModalOpen);
  };

  useEffect(() => {
    if (isUserAuthorized) {
      const acceptedDisclaimer = persistedSettings.usersWhoAcceptedDisclaimer.includes(userPeopleHash);

      if (acceptedDisclaimer) {
        JL().debug(`Redirecting user to landing page ${userLandingPage}.`);
        redirectToUrl(`${userLandingPage}accessToken=${accessToken}`);
        return;
      }

      setRedirectRoute(Routes.Disclaimer);
    } else if (fatalAuthorizationError) {
      setRedirectRoute(Routes.Error);
    }
  }, [isUserAuthorized, fatalAuthorizationError]);

  if (redirectRoute) {
    return <Redirect to={redirectRoute} />;
  }

  return (
    <React.Fragment>
      <div className="sd-header sd-branding-color-border">
        <SmartDriveLogo className="app-logo" />
      </div>
      <Content>
        <div className="sd-content sd-primary-background-color-1-fade">
          <div className='page-header'>
            <div className='login-page-header'>
              <h1>
                <FormattedMessage id="Login.LogIn" defaultMessage="Log in" />
              </h1>
              <Icon
                icon={IconNames.InfoCircleO}
                size={20}
                color="#0076A1"
                className="info-icon"
                onClick={toggleHelpModal}
              />
            </div>
            <div>
              <LoginErrors />
            </div>
          </div>
          <LoginContext.Consumer>
            {(loginContext) => (
              <div>
                {!isAccessError(loginContext.loginError.messageDescriptor.id) && <LoginForm />}
                {isAccessError(loginContext.loginError.messageDescriptor.id) && <NoForm />}
                <Modal
                  visible={isHelpModalOpen}
                  onCancel={toggleHelpModal}
                  destroyOnClose={true}
                  title={
                    <h3>
                      <FormattedMessage id="Common.Help.Title" defaultMessage="Need help?" />
                    </h3>
                  }
                  footer={
                    <Button onClick={toggleHelpModal}>
                      <FormattedMessage id="Common.Close" defaultMessage="CLOSE" />
                    </Button>
                  }>
                  <GenericTroubleshooting />
                </Modal>
              </div>
            )}
          </LoginContext.Consumer>
        </div>
      </Content>
      <React.Suspense fallback={<div />}>
        <AppFooter />
      </React.Suspense>
    </React.Fragment>
  );
};

export default Login;
