import React from 'react';
import { FormattedMessage } from 'react-intl';

export default class GenericTroubleshooting extends React.Component<{}> {
  public render() {
    return (
      <div>
        <p>
            <FormattedMessage
                id="Common.Help.Heading"
                defaultMessage="Contact Global Technical Support:"
            />
        </p>
        <p><strong>
                <a href="mailto:customer.service@smartdrive.net">
                    <FormattedMessage
                        id="Common.Help.Email"
                        defaultMessage="customer.service@smartdrive.net"
                    />
                </a>
        </strong></p>
        <p>
            <strong>
                <FormattedMessage
                    id="Common.Help.TollFreeNumber"
                    defaultMessage="(866) 933-9930"
                />
            </strong> 
            <FormattedMessage
                id="Common.Help.TollFreeNumberText"
                defaultMessage="(US Toll-free)"
            />
            <br />
            <strong>
                <FormattedMessage
                    id="Common.Help.Number"
                    defaultMessage="0800 0470968"
            />
            </strong> 
            <FormattedMessage
                id="Common.Help.NumberText"
                defaultMessage="(UK)"
            />
        </p>
      </div>
    );
  }
}
