import { JL } from "jsnlog";
import { ConfigState } from "../contexts/AppConfigContext";
import { HttpHeaders } from "../models/HttpHeaders";

enum LoggingLevels {
  TRACE = 1000,
  DEBUG = 2000,
  INFO = 3000,
  WARN = 4000,
  ERROR = 5000,
  FATAL = 6000
}

const levelFromString = (level?: string) => {
  switch(level?.toLowerCase()) { 
    case 'trace': { 
      return LoggingLevels.TRACE; 
    } 
    case 'debug': { 
      return LoggingLevels.DEBUG; 
    } 
    case 'info': { 
      return LoggingLevels.INFO; 
    }
    case 'warn': { 
      return LoggingLevels.WARN; 
    } 
    case 'error': { 
      return LoggingLevels.ERROR; 
    } 
    case 'fatal': { 
      return LoggingLevels.FATAL; 
    } 
    default: { 
      // unknown logging level; 
      return undefined; 
    } 
  } 
}

export const setupJsnLogWithAjaxAppender = (accessToken: string, config: ConfigState) => {
  let ajaxAppender: JL.JSNLogAjaxAppender | undefined;

  try {
    ajaxAppender = JL.createAjaxAppender('ajaxAppender');
  } catch (error) {
    JL().error(error);
    return;
  }

  if (!ajaxAppender.setOptions) {
    return;
  }

  ajaxAppender.setOptions({
    level: levelFromString(config.loggingLevel),
    beforeSend: (xhr: XMLHttpRequest, json: any) => {
      xhr.setRequestHeader('app-name', 'SignIn Microsite');

      if (accessToken) {
        xhr.setRequestHeader(HttpHeaders.Authorization, `Bearer ${accessToken}`);
        xhr.setRequestHeader(HttpHeaders.IdToken, accessToken);
      }
    },
  });

  JL.setOptions({
    defaultAjaxUrl: config.loggingServiceUrl,
  });

  JL().setOptions({
    appenders: [ajaxAppender],
    level: levelFromString(config.loggingLevel)
  });
}
