import React from 'react';

export interface AuthorizationState {
  isAuthorizing: boolean,
  isUserAuthorized: boolean,
  fatalAuthorizationError: boolean,
  userPeopleHash: string,
  userLandingPage: string
}

export const AuthorizationContext = React.createContext<AuthorizationState>({
  isAuthorizing: false,
  isUserAuthorized: false,
  fatalAuthorizationError: false,
  userPeopleHash: "",
  userLandingPage: ""
});
