import React from 'react';
import { LoginError } from '../models/LoginError';
import { AlertType } from '../models/AlertType';

export interface LoginState {
  loginError: LoginError,
  updateLoginError: (loginError: LoginError) => void;
}

export const LoginContext = React.createContext<LoginState>({
  loginError: new LoginError(AlertType.Info, {}, {}),
  updateLoginError: (_: LoginError) => {}
});
