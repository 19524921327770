import React, { useState, useContext, useEffect } from 'react';
import { AuthorizationContext } from '../contexts/AuthorizationContext';
import { useAuthentication } from './AuthenticationProvider';
import { useLogin } from './LoginProvider';
import { LoginError } from '../models/LoginError';
import { AlertType } from '../models/AlertType';
import { useLazyQuery } from '@apollo/react-hooks';
import { AppConfigContext } from '../contexts/AppConfigContext';
import { hasEntitlement } from '../utils/authorization';
import { getAuthorizationsQuery } from '../gql/queries/getAuthorizationsQuery';
import { getAuthorizations } from '../gql/queries/__generated__/getAuthorizations';
import { ErrorTypes } from '../apis/Authentication.api';
import { isFatalError } from '../utils/errorClassification';
import { JL } from 'jsnlog';
import { GraphQLError } from 'graphql';

export const useAuthorization = () => {
  return useContext(AuthorizationContext);
}

const AuthorizationProvider: React.FunctionComponent = ({ children }) => {
  const { isAuthenticated } = useAuthentication();
  const [isUserAuthorized, setIsUserAuthorized] = useState(false);
  const [fatalAuthorizationError, setFatalAuthorizationError] = useState(false);
  const [userPeopleHash, setUserPeopleHash] = useState("");
  const [userLandingPage, setLandingPage] = useState("");
  const [isAuthorizing, setIsAuthorizing] = useState(false);
  const { updateLoginError } = useLogin();
  const [loadAuthorizationsData, { data: authorizationsData, error: authorizationError }] = useLazyQuery<getAuthorizations>(getAuthorizationsQuery);
  const config = useContext(AppConfigContext);
  const forbiddenErrorCode = '403';

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    const resources = [config.falconAppId];
    const applicationId = config.falconAppId;
    setIsAuthorizing(true);
    JL().debug('Querying GQL for authorization data.');
    loadAuthorizationsData({ variables: { resources, applicationId } });
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated || (!authorizationsData && !authorizationError)) {
      return;
    }

    const logFailedAuthorization = function (isUserCompanyEntitled: boolean, gqlErrorCodes?: any[], gqlErrors?: readonly GraphQLError[] | undefined) {
      if (!gqlErrors && !isUserCompanyEntitled) {
        JL().info(`Company is not entitled for Falcon.`);
      } else {
        JL().error(`Authorization failed with error(s): ${JSON.stringify(gqlErrors)}`);
      } 

      if (gqlErrorCodes?.some(e => e === forbiddenErrorCode)) {
        JL().info(`User is not authorized for Falcon.`);
      }
    }
    
    const setDisplayError = function (isUserCompanyEntitled: boolean, gqlErrorCodes?: any[]) {
      let errorId: ErrorTypes = ErrorTypes.Unknown;
      let errorDefaultMessage: string;

      if ((!gqlErrorCodes && !isUserCompanyEntitled) || gqlErrorCodes?.some(e => e === forbiddenErrorCode)) {
        errorId = ErrorTypes.UserIsNotAuthorized;
        errorDefaultMessage = 'You do not have sufficient permission to access this application. Please contact your system administrator for access.';
      } else {
        errorId = ErrorTypes.ServerError;
        errorDefaultMessage = 'Unexpected error occurred.';
      }
      const error = {
        id: errorId,
        defaultMessage: errorDefaultMessage
      };
      const loginError: LoginError = new LoginError(AlertType.Error, error);
      updateLoginError(loginError);
      if (isFatalError(loginError.messageDescriptor.id)) {
        setFatalAuthorizationError(true);
      }   
    } 

    JL().debug("Completed GQL authorization query.");
    const gqlErrors = authorizationError?.graphQLErrors;
    const gqlErrorCodes = authorizationError?.graphQLErrors.map(e => e?.extensions?.code);
    const hasAuthorizations = !!authorizationsData?.authorizations?.length;
    const isEntitledToFalcon = hasEntitlement(authorizationsData?.me, config.falconAppId);
    const isAuthorized =  hasAuthorizations && isEntitledToFalcon;
    const peopleHash = authorizationsData?.me?.peopleHash ?? "";
    const landingPage = authorizationsData?.me?.applicationMetadata?.landingPage?.destination ?? "";
                          
    if (isAuthorized) {
      setIsUserAuthorized(isAuthorized);
      setUserPeopleHash(peopleHash);
      setLandingPage(landingPage);
      JL().debug(`People hash ${peopleHash} is authorized and entitled for Falcon.`);
    } else {
      logFailedAuthorization(isEntitledToFalcon, gqlErrorCodes, gqlErrors);
      setDisplayError(isEntitledToFalcon, gqlErrorCodes);
    }

    setIsAuthorizing(false);
  }, [authorizationsData, authorizationError, isAuthenticated]);

  return (
    <AuthorizationContext.Provider
      value={{
        isAuthorizing,
        isUserAuthorized,
        fatalAuthorizationError,
        userPeopleHash,
        userLandingPage
      }}>
      {children}
    </AuthorizationContext.Provider>
  );
};

export default AuthorizationProvider;
