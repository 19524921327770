import { gql } from '@apollo/react-hooks';

export const getAuthorizationsQuery = gql`
  query getAuthorizations($resources: [String], $applicationId: ID!) {
    authorizations (resources: $resources) {
      resource
      role
      permission
      userScope
      permissionScope
    }
    me{
      peopleHash
      applicationMetadata(applicationId: $applicationId) {
        landingPage {
          destination
        }
      } 
      company {
        name
        entitlements {
          edges {
            node {
              id
              name
            } 
          }
        } 
      }
    }
  }
`