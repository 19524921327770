import React, { useState, useContext } from 'react';
import { LoginError } from '../models/LoginError';
import { AlertType } from '../models/AlertType';
import { LoginContext } from '../contexts/LoginContext';

export const useLogin = () => {
  return useContext(LoginContext);
}

const LoginProvider: React.FunctionComponent = ({ children }) => {

  const [loginError, setLoginError] = useState(new LoginError(AlertType.Info, {}, {}),);

  const updateLoginError = (newLoginError: LoginError) => {
    setLoginError(newLoginError);
  };

  return (
    <LoginContext.Provider
      value={{
        loginError,
        updateLoginError
      }}>
      {children}
    </LoginContext.Provider>
  );
};

export default LoginProvider;
