import axios, { AxiosError } from 'axios';
import { AuthenticationResponse } from '../models/AuthenticationResponse';
import { LoginError } from '../models/LoginError';
import { AlertType } from '../models/AlertType';
import { ErrorTypes } from '../utils/errorClassification';

const getExpiresInFromExpiresAt = (expiresAt: number) => {
  return (expiresAt - new Date().getTime()) / 1000;
};

const postLogin = async (authenticationServiceUrl: string, company: string, username: string, password: string, timeout = 10000): Promise<AuthenticationResponse> => {  
  const response = await axios.post<AuthenticationResponse>(
    `${authenticationServiceUrl}/login`,
    {
      company,
      username,
      password
    },
    { timeout }
  );

  return response.data as AuthenticationResponse;
}

const deleteSession = async (authenticationServiceUrl: string, timeout = 10000) => {
  await axios.delete(`${authenticationServiceUrl}/session`, {withCredentials: true, timeout});
}

const constructLoginError = (error: AxiosError): LoginError => {
  let loginError: LoginError;

  if (!error.response) {
    loginError = new LoginError(AlertType.Error,
      {
        id: ErrorTypes.Unknown,
        defaultMessage: 'An unknown login error occurred. Try again.'
      });
  } else {
    switch (error.response.status) {
      case 401:
        loginError = new LoginError(AlertType.Error,
          {
            id: ErrorTypes.IncorrectLoginInformation,
            defaultMessage: 'Company, username and password do not match.'
          });
        break;
      case 403:
        loginError = new LoginError(AlertType.Error,
          {
            id: ErrorTypes.PermissionError,
            defaultMessage: 'You do not have sufficient permission to access this application. Please contact your system administrator for access.'
          });
        break;
      case 400:
        loginError = new LoginError(AlertType.Error,
          {
            id: ErrorTypes.BadRequestError,
            defaultMessage: 'The company name, user name, or password is missing.'
          });
        break;
      case 500:
        loginError = new LoginError(AlertType.Error,
          {
            id: ErrorTypes.ServerError,
            defaultMessage: 'Unexpected error occurred.'
          });
        break;
      case 404:
        loginError = new LoginError(AlertType.Error,
          {
            id: ErrorTypes.ServerError,
            defaultMessage: 'Unexpected error occurred.'
          });
        break;
      default:
        loginError = new LoginError(AlertType.Error,
          {
            id: ErrorTypes.Unknown,
            defaultMessage: 'Unexpected error occurred.'
          });
        break;
    }
  }

  return loginError;
}

export {
  ErrorTypes,
  getExpiresInFromExpiresAt,
  postLogin,
  deleteSession,
  constructLoginError
};
