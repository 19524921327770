import axios from 'axios';
import { ApplicationConfiguration } from '../models/ApplicationConfiguration';
const { PUBLIC_URL } = process.env;


/**
 * Fetches the configuration object from config/config.json.
 *
 * @throws { AxiosError }
 * @returns { Promise<ApplicationConfiguration> }
 */
const getConfig = async (): Promise<ApplicationConfiguration> => {
  const response = await axios.get<ApplicationConfiguration>(
    `${PUBLIC_URL}/config/config.json?timestamp=${new Date().getTime()}`
  );

  return response.data;
};

export { getConfig };
