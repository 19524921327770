import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Routes } from '../../models/Routes';
import { useAuthentication } from '../../providers/AuthenticationProvider';

export const Logout: React.FunctionComponent = () => {
  const { logout } = useAuthentication();
  const [userHasBeenLoggedOut, setUserHasBeenLoggedOut] = useState(false);

  const logUserOut = async ()=> {
    await logout();
    setUserHasBeenLoggedOut(true);
  }

  useEffect(()=> {
    logUserOut();
  }, [])
  
  return (
    <React.Fragment>
      { userHasBeenLoggedOut && <Redirect to={Routes.Login}/> }
    </React.Fragment>
  );
}

export default Logout;