import React, { useContext } from 'react';
import { AppConfigContext} from '../../contexts/AppConfigContext';

export const ApplicationRoot:React.FunctionComponent = ({children}) => {
  const config = useContext(AppConfigContext);

  if (!config.isLoaded) {
    return null;
  }

  return ( 
    <React.Fragment>
        {children}
    </React.Fragment>
  )
}

export default ApplicationRoot;