import React, { useContext } from 'react';
import { Layout } from '@sd-ui-components/core';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import { history } from './history';
import { Routes } from './models/Routes';
import { Login } from './components/Login/Login';
import { Logout } from './components/Logout/Logout';
import './components/AppPage/AppPage.scss';
import { FatalError } from './components/FatalError/FatalError';
import Disclaimer from './components/Disclaimer/Disclaimer';
import { AppConfigContext } from './contexts/AppConfigContext';
import { useGoogleAnalytics } from '@sd-ui/analytics';

export const MainRoutes: React.FunctionComponent = () => {
  return (
    <Router history={history}>
      <InnerRoutes />
    </Router>
  );
};

const InnerRoutes: React.FunctionComponent = () => {
  const config = useContext(AppConfigContext);
  useGoogleAnalytics(config.googleAnalyticsTrackingId || '');

  return (
    <div className="application-layout-root">
      <div className="application-window-tint">
        <Layout className="application-layout">
          <React.Fragment>
            <Switch>
              {/* Routes go here */}
              <Route path={Routes.Login} component={Login} />
              <Route path={Routes.Logout} component={Logout} />
              <Route path={Routes.Error} component={FatalError} />
              <Route path={Routes.Disclaimer} component={Disclaimer} />
              <Route path="*">
                <Redirect to={Routes.Login}/>
              </Route>
            </Switch>            
          </React.Fragment>
        </Layout>
      </div>
    </div >
  );
}
