import React from 'react';
import { UserInfo } from '../models/UserInfo';

export interface AuthenticationState {
  accessToken: string,
  expiresAt: number,
  expiresIn(): number,
  isTokenExpired: boolean,
  isAuthenticated: boolean,
  userInfo: UserInfo,
  isLoggingIn: boolean,

  login: (company: string, username: string, password: string) => void,
  logout: () => void
}

export const AuthenticationContext = React.createContext<AuthenticationState>({
  accessToken: '',
  expiresAt: 0,
  expiresIn: () => 0,
  isTokenExpired: true,
  isAuthenticated: false,
  userInfo: {},
  isLoggingIn: false,

  login: (_: string) => { },
  logout: () => { }
});
