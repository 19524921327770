export enum Routes {
  Login = '/login',
  Logout = '/logout',
  ForgotPassword = '/login/forgot-password',
  AppSelection = '/apps',
  Callback = '/callback/:type?',
  Unauthorized = '/unauthorized',
  NoPermissions = '/permissions-error',
  Error = '/error',
  Disclaimer = '/disclaimer'
}