import { getAuthorizations_me } from "../gql/queries/__generated__/getAuthorizations";

export const hasEntitlement = (me: getAuthorizations_me | null | undefined, entitlementToCheck: string | undefined) => {
  if (!entitlementToCheck || !me?.company?.entitlements) {
    return false;
  }

  const entitlements = me.company.entitlements;

  const isEntitled = !!entitlements.edges?.find(
    (x =>  { return x?.node?.name?.toLowerCase() === entitlementToCheck.toLowerCase()})
  );

  return isEntitled;
}