import React, { useState, useEffect } from 'react';
import { AppConfigContext, ConfigState } from '../contexts/AppConfigContext';
import { getConfig } from '../apis/ConfigApi';

export const AppConfigProvider: React.FunctionComponent = ({children}) => {
  const [config, setConfig] = useState<ConfigState>({ isLoaded: false});
  
  useEffect(() => {
    const loadConfigAsync = async () => {
      setConfig({ isLoaded: true, ...await getConfig()});
    }
    loadConfigAsync();
  }, []);

  return (
    <AppConfigContext.Provider value={config}>
      { children }
    </AppConfigContext.Provider>
  )
}

export default AppConfigProvider;