import React, { useContext, useEffect, useState } from 'react';
import { Button, IconNames, Icon, Modal } from '@sd-ui-components/core';
import { FormattedMessage } from 'react-intl';
import './Disclaimer.scss';
import GenericTroubleshooting from '../Common/GenericTroubleshooting';
import { redirectToUrl } from '../../utils/redirect';
import { useAuthentication } from '../../providers/AuthenticationProvider';
import { Routes } from '../../models/Routes';
import { useAuthorization } from '../../providers/AuthorizationProvider';
import { usePersistedSettings } from '../../hooks/usePersistedSettings';
import { AppConfigContext } from '../../contexts/AppConfigContext';
import { ReactComponent as SmartDriveLogo } from '../../assets/smartdrive_logo_light.svg';
import { JL } from 'jsnlog';

const AppFooter = React.lazy(() => import('../AppPage/AppFooter'));

export const Disclaimer: React.FunctionComponent = () => {
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const { isUserAuthorized, userPeopleHash, userLandingPage } = useAuthorization();
  const { accessToken } = useAuthentication();
  const [persistedSettings, setPersistedSettings] = usePersistedSettings();
  const config = useContext(AppConfigContext);
  const [isProcessingDisclaimerAction, setIsProcessingDisclaimerAction] = useState(false);

  const toggleHelpModal = () => {
    setIsHelpModalOpen(!isHelpModalOpen)
  };

  useEffect(() => {
    if (!isUserAuthorized) {
      redirectToUrl(Routes.Login);
    }
  }, [isUserAuthorized]);

  const handleDisclaimerAction = async () => {
    setIsProcessingDisclaimerAction(true);

    if(!persistedSettings?.usersWhoAcceptedDisclaimer.includes(userPeopleHash)) {
      const peopleHashes = persistedSettings?.usersWhoAcceptedDisclaimer;
      peopleHashes.push(userPeopleHash);

      setPersistedSettings(JSON.stringify({ usersWhoAcceptedDisclaimer: peopleHashes }));
      JL().info(`Saved disclaimer acceptance for people hash: ${userPeopleHash} in local storage.`);
    }

    JL().debug(`Redirecting user to landing page ${userLandingPage}.`);
    redirectToUrl(`${userLandingPage}accessToken=${accessToken}`);
    setIsProcessingDisclaimerAction(false);
  }

  return (
    <React.Fragment>
      <div className="sd-header sd-branding-color-border">
        <SmartDriveLogo className="app-logo" />
      </div>
      <div className="sd-content sd-primary-background-color-1-fade">
        <div className='page-header disclaimer-page-header'>
          <h1>
            <FormattedMessage
              id="Disclaimer.Title"
              defaultMessage="Beta agreement"
            />
          </h1>
          <Icon
            icon={IconNames.InfoCircleO}
            size={20}
            color='#0076A1'
            className='info-icon'
            onClick={toggleHelpModal}
          />
        </div>
        <div className='page-content'>
          <div>
          <FormattedMessage id="Disclaimer.Body" 
            defaultMessage="<p>Welcome to the beta version of SmartDrive’s new application!</p><p>As a beta user, you will get early access to new features as they become available. As we launch additional functionality, we’d love to hear your feedback so please send them to <email></email>!</p><p>By selecting Accept and Continue, you agree to our <termsLink>Terms and Conditions</termsLink>.</p>" 
            values={{ 
              p: (msg: any) => <p>{msg}</p>,
              email: () => <a href={`mailto: ${config.feedbackEmail}`} target="_blank" rel="noopener noreferrer">{config.feedbackEmail}</a>,
              termsLink: (msg: any) => <a href={config.termsUrl} target="_blank" rel="noopener noreferrer">{msg}</a>,
            }} 
          />
          </div>
        </div>

        <div className='page-actions'>
          <div>
            <Button
              type="primary"
              size="large"
              className="disclaimer-button"
              onClick={handleDisclaimerAction}
              loading={false}
            >
              {isProcessingDisclaimerAction ? (
              <div className="loading">
                <Icon className="rotate" icon={IconNames.Loading} />
              </div>
              ) : (<FormattedMessage id="Disclaimer.AcceptTerms" defaultMessage="Accept and Continue" />)}
            </Button>
          </div>
        </div>
        <Modal
          visible={isHelpModalOpen}
          onCancel={toggleHelpModal}
          destroyOnClose={true}
          title={
            <h3>
              <FormattedMessage id="Common.Help.Title" defaultMessage="Need help?" />
            </h3>
          }
          footer={
            <Button onClick={toggleHelpModal}>
              <FormattedMessage id="Common.Close" defaultMessage="CLOSE" />
            </Button>
          }>
          <GenericTroubleshooting />
        </Modal>
      </div>
      <React.Suspense fallback={<div />}>
        <AppFooter />
      </React.Suspense>
    </React.Fragment>
  )
}

export default Disclaimer;
