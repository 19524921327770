import React, { useContext } from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { AppConfigContext } from '../contexts/AppConfigContext';
import { setContext } from '@apollo/client/link/context';
import { useAuthentication } from './AuthenticationProvider';

export const ApolloClientProvider: React.FunctionComponent = ({ children }) => {
  const config = useContext(AppConfigContext);
  const { accessToken } = useAuthentication();

  const httpLink = createHttpLink({
    uri: config.federatedGraphQlHubServiceUrl,
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        Cookie: `authorization=${accessToken}`,
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json, text/plain, */*',
        'Id-JWT-Token': accessToken,
      }
    }
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  });

  return (<ApolloProvider client={client}>{children}</ApolloProvider>);
}

export default ApolloClientProvider;

